<template>
  <section>
    <b-card class="mt-2">
      <div class="mt-2">
        <dl class="mt-2">
          <dt>{{ $t("NAME") }}</dt>
          <dd>{{ information.name }}</dd>
          <dt>{{ $t("CREATED") }}</dt>
          <dd>
            {{
              FormatDateTime(information.created_date)
            }}
          </dd>
          <dt>{{ $t("ORGANISATION.UNIQUE_IDENTIFIER") }}</dt>
          <dd>{{ information.unique_identifier }}</dd>
          <dt v-if="information.sefos_node == 0">{{ $t("STATE") }}</dt>
          <dd v-if="information.sefos_node == 0">
            {{ showState(information.state) }}
          </dd>
          <dt>{{ $t("ACCOUNTS") }}</dt>
          <dd>{{ information.current_accounts }}</dd>
          <dt v-if="information.sefos_node == 0">{{ $t("STORAGE") }}</dt>
          <dd v-if="information.sefos_node == 0">
            {{ bytesToSize(information.current_storage) }}
          </dd>
          <dt v-if="information.sefos_node == 0">
            {{ $t("ORGANISATION.SENT_MESSAGES_LAST10DAYS") }}
          </dt>
          <dd v-if="information.sefos_node == 0">
            {{ information.sent_messages_last10days }}
          </dd>
        </dl>
      </div>
    </b-card>

    <b-card class="informationbox" no-body>
      <template v-slot:header>
        {{ $t("PRICES") }}
      </template>
        <b-card-body class="p-0">
          <dl class="clearfix pb-2">
              <dt>{{ $t("LICENSE.TITLE") }}</dt>
              <dd>
                {{ information.prices.license }} {{ information.prices.currency }}
                {{ $t("PER_MONTH") }}<br />
                {{ information.prices.included_accounts }}
                {{ $t("LICENSE.INCLUDED_ACCOUNTS") }}
              </dd>
              <dt>{{ $t("LICENSE.ACCOUNTS") }}</dt>
              <dd>
                {{ information.prices.accounts }} {{ information.prices.currency }}
                {{ $t("EACH_PER_MONTH") }}
              </dd>
            </dl>
        </b-card-body>
        <b-card-header class="pb-0"><span class="card-body-title">{{ $t("BILLING.AUTHENTICATIONS") }}</span></b-card-header>
        <b-card-body class="ml-2 p-0">
            <dl class="mt-2 clearfix">
              <dt>{{ $t("AUTH_METHODS.freja") }}</dt>
              <dd>
                {{ information.prices.login_freja }}
                {{ information.prices.currency }} {{ $t("EACH") }}
              </dd>
              <dt>{{ $t("AUTH_METHODS.bankid-se") }}</dt>
              <dd>
                {{ information.prices.login_bankid_se }}
                {{ information.prices.currency }} {{ $t("EACH") }}
              </dd>
            </dl>
        </b-card-body>
        <b-card-header class="pb-0"><span class="card-body-title">{{ $t("BILLING.VERIFICATIONS") }}</span></b-card-header>
        <b-card-body class="p-0 ml-2">
            <dl class="mt-2 clearfix">
              <dt>{{ $t("AUTH_METHODS.freja") }}</dt>
              <dd>
                {{ information.prices.verify_freja }}
                {{ information.prices.currency }} {{ $t("EACH") }}
              </dd>
              <dt>{{ $t("AUTH_METHODS.bankid-se") }}</dt>
              <dd>
                {{ information.prices.verify_bankid_se }}
                {{ information.prices.currency }} {{ $t("EACH") }}
              </dd>
              <dt>{{ $t("AUTH_METHODS.sms") }}</dt>
              <dd>
                {{ information.prices.verify_sms }}
                {{ information.prices.currency }} {{ $t("EACH") }}
              </dd>
            </dl>
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      information: this.organisation,
      server_url: window.location.origin,
    };
  },
  methods: {
    showState: function(state) {
      return state === 1 ? this.$t("ACTIVE") : this.$t("INACTIVE");
    },
    showInvite: function(state) {
      return state === 0 ? this.$t("NO") : this.$t("YES");
    },
    showValidated: function(state) {
      return state === 0 ? this.$t("NO") : this.$t("YES");
    },
    showAdmin: function(state) {
      return state === 0 ? this.$t("NO") : this.$t("YES");
    },
  },
  mounted: function() {},
};
</script>
<style></style>
