var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-card", { staticClass: "mt-2" }, [
        _c("div", { staticClass: "mt-2" }, [
          _c("dl", { staticClass: "mt-2" }, [
            _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
            _c("dd", [_vm._v(_vm._s(_vm.information.name))]),
            _c("dt", [_vm._v(_vm._s(_vm.$t("CREATED")))]),
            _c("dd", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.FormatDateTime(_vm.information.created_date)) +
                  "\n        "
              ),
            ]),
            _c("dt", [
              _vm._v(_vm._s(_vm.$t("ORGANISATION.UNIQUE_IDENTIFIER"))),
            ]),
            _c("dd", [_vm._v(_vm._s(_vm.information.unique_identifier))]),
            _vm.information.sefos_node == 0
              ? _c("dt", [_vm._v(_vm._s(_vm.$t("STATE")))])
              : _vm._e(),
            _vm.information.sefos_node == 0
              ? _c("dd", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.showState(_vm.information.state)) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _c("dt", [_vm._v(_vm._s(_vm.$t("ACCOUNTS")))]),
            _c("dd", [_vm._v(_vm._s(_vm.information.current_accounts))]),
            _vm.information.sefos_node == 0
              ? _c("dt", [_vm._v(_vm._s(_vm.$t("STORAGE")))])
              : _vm._e(),
            _vm.information.sefos_node == 0
              ? _c("dd", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bytesToSize(_vm.information.current_storage)) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.information.sefos_node == 0
              ? _c("dt", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("ORGANISATION.SENT_MESSAGES_LAST10DAYS")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.information.sefos_node == 0
              ? _c("dd", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.information.sent_messages_last10days) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "b-card",
        {
          staticClass: "informationbox",
          attrs: { "no-body": "" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v("\n      " + _vm._s(_vm.$t("PRICES")) + "\n    "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-card-body", { staticClass: "p-0" }, [
            _c("dl", { staticClass: "clearfix pb-2" }, [
              _c("dt", [_vm._v(_vm._s(_vm.$t("LICENSE.TITLE")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.license) +
                    " " +
                    _vm._s(_vm.information.prices.currency) +
                    "\n              " +
                    _vm._s(_vm.$t("PER_MONTH"))
                ),
                _c("br"),
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.included_accounts) +
                    "\n              " +
                    _vm._s(_vm.$t("LICENSE.INCLUDED_ACCOUNTS")) +
                    "\n            "
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("LICENSE.ACCOUNTS")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.accounts) +
                    " " +
                    _vm._s(_vm.information.prices.currency) +
                    "\n              " +
                    _vm._s(_vm.$t("EACH_PER_MONTH")) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
          _c("b-card-header", { staticClass: "pb-0" }, [
            _c("span", { staticClass: "card-body-title" }, [
              _vm._v(_vm._s(_vm.$t("BILLING.AUTHENTICATIONS"))),
            ]),
          ]),
          _c("b-card-body", { staticClass: "ml-2 p-0" }, [
            _c("dl", { staticClass: "mt-2 clearfix" }, [
              _c("dt", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.freja")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.login_freja) +
                    "\n              " +
                    _vm._s(_vm.information.prices.currency) +
                    " " +
                    _vm._s(_vm.$t("EACH")) +
                    "\n            "
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.bankid-se")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.login_bankid_se) +
                    "\n              " +
                    _vm._s(_vm.information.prices.currency) +
                    " " +
                    _vm._s(_vm.$t("EACH")) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
          _c("b-card-header", { staticClass: "pb-0" }, [
            _c("span", { staticClass: "card-body-title" }, [
              _vm._v(_vm._s(_vm.$t("BILLING.VERIFICATIONS"))),
            ]),
          ]),
          _c("b-card-body", { staticClass: "p-0 ml-2" }, [
            _c("dl", { staticClass: "mt-2 clearfix" }, [
              _c("dt", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.freja")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.verify_freja) +
                    "\n              " +
                    _vm._s(_vm.information.prices.currency) +
                    " " +
                    _vm._s(_vm.$t("EACH")) +
                    "\n            "
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.bankid-se")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.verify_bankid_se) +
                    "\n              " +
                    _vm._s(_vm.information.prices.currency) +
                    " " +
                    _vm._s(_vm.$t("EACH")) +
                    "\n            "
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.sms")))]),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.information.prices.verify_sms) +
                    "\n              " +
                    _vm._s(_vm.information.prices.currency) +
                    " " +
                    _vm._s(_vm.$t("EACH")) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }